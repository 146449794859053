import ImageWithTitleI from "./ImageWithTitleI.type";
import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "./ImageWithTitle.module.scss";
import { cn } from "../../../utils";
import Button from "../../Button/Button";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import ContactForm from "../../Scripts/Scripts/ContactForm";

const ImageWithTitle = ({
  title,
  textPosition,
  image,
  background,
  button,
  firstModule = false,
  richTextSubtext,
  mobileOrderReverse = true,
  mobileCenterAlign = true,
  form,
}: ImageWithTitleI) => {
  return (
    <div
      className={cn([
        styles[textPosition],
        styles.iwtContainer,
        styles[background],
        firstModule ? styles.firstModule : "",
        mobileOrderReverse ? styles.reverse : "",
        mobileCenterAlign ? styles.mobileCenter : "",
      ])}
    >
      <div className={styles.imageContainer}>
        <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
      </div>
      <div className={styles.titleContainer}>
        <h2>
          <span>{title.title}</span>&nbsp;
          <span className={styles[title.second_title_color]}>
            {title.second_title}
          </span>
        </h2>
        {title.description ? <p>{title.description}</p> : ""}
        <div></div>
        <div className={styles[title.second_title_color]}>
          {richTextSubtext ? renderRichText(richTextSubtext) : ""}
        </div>
        {form ? (
          <div className={styles.formContainer}>
            <ContactForm
              targetId={form.targetId}
              formId={form.formId}
            ></ContactForm>
          </div>
        ) : (
          button && (
            <div className={styles.buttonContainer}>
              <Button
                text={button.text}
                color={button.color}
                size={button.size}
                onClick={button.onClick}
                url={button.url}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ImageWithTitle;
