// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var iwtContainer = "ImageWithTitle-module--iwtContainer--3HcQG";
export var grey = "ImageWithTitle-module--grey--1QSEQ";
export var left = "ImageWithTitle-module--left--13dik";
export var reverse = "ImageWithTitle-module--reverse--1LVSQ";
export var titleContainer = "ImageWithTitle-module--titleContainer--3Ag0k";
export var right = "ImageWithTitle-module--right--1L-3u";
export var firstModule = "ImageWithTitle-module--firstModule--NhyBu";
export var blue = "ImageWithTitle-module--blue--bcAQz";
export var green = "ImageWithTitle-module--green--1T1tb";
export var buttonContainer = "ImageWithTitle-module--buttonContainer--10ndl";
export var mobileCenter = "ImageWithTitle-module--mobileCenter--oc1xh";
export var gatsbyImageWrapper = "ImageWithTitle-module--gatsby-image-wrapper--1oC5a";
export var imageContainer = "ImageWithTitle-module--imageContainer--Zh6m7";