import IconCardRowI from "./IconCardRowI.type";
import React from "react";
import * as styles from "./IconCardRow.module.scss";
import { cn } from "../../../utils";
import IconCard from "../IconCard/IconCard";

const IconCardRow = ({
  iconCards,
  alignment,
  padding,
  radius,
  bullets,
}: IconCardRowI) => {
  return (
    <div
      className={cn([
        iconCards.length % 3 === 0
          ? styles.threeIconCardRowContainer
          : styles.fourIconCardRowContainer,
      ])}
    >
      {iconCards.map((iconCard, index) => {
        return (
          <IconCard
            index={index}
            title={iconCard.title}
            subtext={iconCard.subtext}
            icon={iconCard.icon}
            alignment={alignment}
            padding={padding}
            radius={radius}
            bullets={bullets}
          ></IconCard>
        );
      })}
    </div>
  );
};

export default IconCardRow;
