import FeatureCardI from "./FeatureCardI.type";
import * as styles from "./FeatureCard.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "../../Button/Button";
import { cn } from "../../../utils";
import { getReadingTimeFromRaw } from "../../../utils/helpers";

const FeatureCard = ({
  tag,
  title,
  subTitle,
  buttonText = "",
  sectionOneContent,
  sectionTwoContent,
  sectionThreeContent,
  image,
  link,
  internalLinkId,
  fullWidth = false,
  whiteCard = false,
}: FeatureCardI) => {
  const readTime = getReadingTimeFromRaw([
    sectionOneContent?.raw ? sectionOneContent.raw : "",
    sectionTwoContent?.raw ? sectionTwoContent.raw : "",
    sectionThreeContent?.raw ? sectionThreeContent.raw : "",
  ]);
  return (
    <>
      <div
        className={cn([
          styles.featureCardImgContainer,
          fullWidth ? styles.fullWidth : "",
        ])}
      >
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.title}
          className={styles.featureGatsbyImage}
        ></GatsbyImage>
        <div
          className={cn([
            styles.featureCardContainer,
            whiteCard ? styles.white : "",
          ])}
        >
          {link ? (
            <a href={`/blog/${link}`} className={styles.featureCardLink}>
              <div className={styles.featureCardTag}>{tag}</div>
              <div className={styles.featureCardTitle}>{title}</div>
              <div className={styles.featureCardExcerpt}>{subTitle}</div>
              <div className={styles.featureCardReadTime}>{readTime}</div>
            </a>
          ) : (
            <>
              <div className={styles.featureCardTitle}>{title}</div>
              <div className={styles.featureCardExcerpt}>{subTitle}</div>
              <div className={styles.featureCardButton}>
                <Button
                  text={buttonText}
                  size={"medium"}
                  url={`#${internalLinkId}`}
                  contactButton={false}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FeatureCard;
