import { useEffect, useState } from "react";
import LandingPageV2Layout from "../components/LandingPageV2/LandingPageV2Layout/LandingPageV2Layout";
import CookiesBanner from "../components/CookiesBanner/CookiesBanner";
import Layout from "../components/Layout/Layout";
import { useCookie } from "../utils/getCookie";

const landingPageV2 = ({ pageContext }: any) => {
  const node = pageContext.data;
  const dataSEO = {
    metaTitle: node?.metaTitle,
    metaDescription: node?.metaDescription?.metaDescription || "",
    keywords: node?.metaTitle,
    preventIndexing: false,
    alternateHrefLink: true,
  };

  const [showCookiesConsent, setShowCookiesConsent] = useState(false);

  useEffect(() => {
    const trackCookie = useCookie("gatsby-gdpr-tracking");
    const tagCookie = useCookie("via-gdpr-google-tagmanager");
    if (!trackCookie || !tagCookie) {
      setShowCookiesConsent(true);
    }
  }, []);

  const {
    footer_menu,
    header_menu,
    logo,
    cookieBarDescription,
    cookiePolicyLink,
    cookieBarTitle,
    footer,
  } = pageContext.globalSettings;
  return (
    <div>
      <Layout
        footer_menu={footer_menu}
        header_menu={header_menu}
        footer={footer}
        logo={logo}
        background={true}
        page_background={true}
        dataSEO={dataSEO}
        countryPage={false}
        blog={true}
        url={pageContext.url}
        white={true}
        no_menu={true}
        menuCTALink={`#${node.section4CtaFormId}`}
      >
        <LandingPageV2Layout lp={node}></LandingPageV2Layout>
        {showCookiesConsent && (
          <CookiesBanner
            dismiss={() => setShowCookiesConsent(false)}
            cookieBarDescription={cookieBarDescription}
            cookiePolicyLink={cookiePolicyLink}
            cookieBarTitle={cookieBarTitle}
          />
        )}
      </Layout>
    </div>
  );
};

export default landingPageV2;
