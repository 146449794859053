// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var featureCardImgContainer = "FeatureCard-module--featureCardImgContainer--KfRIz";
export var gatsbyImageWrapper = "FeatureCard-module--gatsby-image-wrapper--1FcaR";
export var featureCardContainer = "FeatureCard-module--featureCardContainer--80uoz";
export var featureCardTag = "FeatureCard-module--featureCardTag--1s4aA";
export var featureCardTitle = "FeatureCard-module--featureCardTitle--Yhxy1";
export var featureCardExcerpt = "FeatureCard-module--featureCardExcerpt--21fd_";
export var featureCardReadTime = "FeatureCard-module--featureCardReadTime--39OC6";
export var featureCardLink = "FeatureCard-module--featureCardLink--2ovR1";
export var fullWidth = "FeatureCard-module--fullWidth--3ZS2N";
export var featureGatsbyImage = "FeatureCard-module--featureGatsbyImage--2QeUr";
export var white = "FeatureCard-module--white--7mmMx";
export var featureCardButton = "FeatureCard-module--featureCardButton--3cRjX";