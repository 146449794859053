// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var heroWrapper = "LandingPageV2Layout-module--heroWrapper--15AZS";
export var heroTitleTwo = "LandingPageV2Layout-module--heroTitleTwo--3QPMQ";
export var peach = "LandingPageV2Layout-module--peach--2KQDu";
export var heroSubtitle = "LandingPageV2Layout-module--heroSubtitle--2OYlD";
export var greyBackground = "LandingPageV2Layout-module--greyBackground--3rWTF";
export var featureCardWrapper = "LandingPageV2Layout-module--featureCardWrapper--1fLRm";
export var lpIconRowWrapper = "LandingPageV2Layout-module--lpIconRowWrapper--2Qd9Q";
export var iconTitle = "LandingPageV2Layout-module--iconTitle--3NnFO";
export var lpButtonContainer = "LandingPageV2Layout-module--lpButtonContainer--1i_D0";
export var contactFormWrapper = "LandingPageV2Layout-module--contactFormWrapper--31xnO";
export var contactFormTitle = "LandingPageV2Layout-module--contactFormTitle--1ZtEJ";
export var featureCardContainer = "LandingPageV2Layout-module--featureCardContainer--X1XhH";