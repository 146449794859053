import ContactForm from "../../Scripts/Scripts/ContactForm";
import LandingPageV2LayoutI from "./LandingPageV2LayoutI.type";
import * as styles from "./LandingPageV2Layout.module.scss";
import FeatureCard from "../../Blocks/FeatureCard/FeatureCard";
import ImageWithTitle from "../../PartnershipPage/ImageWithTitle/ImageWithTitle";
import IconCardRow from "../../PartnershipPage/IconCardRow/IconCardRow";
import Faqs from "../../CountryPage/FAQS/Faqs";
import Button from "../../Button/Button";

const LandingPageV2Layout = ({ lp }: LandingPageV2LayoutI) => {
  return (
    <>
      <div className={styles.heroWrapper}>
        <div className={styles.heroTitleOne}>
          {lp?.heroH1TitleStart}{" "}
          <span className={styles.heroTitleTwo}>{lp?.heroH1TitleEnd}</span>
        </div>
      </div>
      <div className={styles.featureCardWrapper}>
        <FeatureCard
          title={lp.heroFeatureCardH2Title}
          subTitle={lp.featureCardSubtext.featureCardSubtext}
          image={lp.heroFeatureCardImage}
          internalLinkId={lp.section4CtaFormId}
          buttonText={"Request a Demo"}
          fullWidth={true}
          whiteCard={true}
        ></FeatureCard>
      </div>
      <ImageWithTitle
        image={lp.section1Image}
        title={{
          title: lp.section1H2TitleStart,
          second_title: lp.section1H2TitleEnd,
          title_color: "dark",
          second_title_color: "green",
          second_line: false,
        }}
        richTextSubtext={lp.section1RichText}
        textPosition="left"
        background="white"
        firstModule={true}
        mobileOrderReverse={false}
        mobileCenterAlign={false}
      ></ImageWithTitle>
      <div className={styles.lpIconRowWrapper}>
        <div className={styles.iconTitle}>{lp?.section2IconsH2Title}</div>
        <IconCardRow
          iconCards={lp.section2IconCards}
          alignment={"center"}
          padding={true}
          radius={true}
          bullets={false}
        ></IconCardRow>
        <div className={styles.lpButtonContainer}>
          <Button
            text={"Request a Demo"}
            color={"blue"}
            size={"medium"}
            url={`#${lp.section4CtaFormId}`}
            contactButton={false}
          />
        </div>
      </div>
      <ImageWithTitle
        image={lp.section3Image}
        title={{
          title: lp.section3H2TitleStart,
          second_title: lp.section3H2TitleEnd,
          title_color: "dark",
          second_title_color: "blue",
          second_line: false,
        }}
        richTextSubtext={lp.section3RichText}
        textPosition="left"
        background="white"
        firstModule={true}
        button={{
          className: "",
          color: "blue",
          size: "medium",
          text: "Request a Demo",
          url: `#${lp.section4CtaFormId}`,
        }}
        mobileOrderReverse={false}
        mobileCenterAlign={false}
      ></ImageWithTitle>
      <div className={styles.contactFormWrapper}>
        <div className={styles.contactFormTitle}>
          <div>{lp?.section4CtaFormH2TitleStart}</div>
          <span className={styles.peach}>{lp?.section4CtaFormH2TitleEnd}</span>
        </div>
        <ContactForm targetId={lp.section4CtaFormId}></ContactForm>
      </div>
      <Faqs faqs={lp.section5Faqs} grey={false}></Faqs>
    </>
  );
};

export default LandingPageV2Layout;
