// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var iconCardWrapper = "IconCard-module--iconCardWrapper--xYPwR";
export var padding = "IconCard-module--padding--1VCKD";
export var center = "IconCard-module--center--3TcII";
export var iconTitleImageWrapper = "IconCard-module--iconTitleImageWrapper--1giAd";
export var left = "IconCard-module--left--2Rn2h";
export var iconTitleContainer = "IconCard-module--iconTitleContainer--119pC";
export var iconImageContainer = "IconCard-module--iconImageContainer--16lNa";
export var radius = "IconCard-module--radius--3E0kF";
export var circle = "IconCard-module--circle--hcex1";
export var topLeft = "IconCard-module--topLeft--2luZU";
export var topRight = "IconCard-module--topRight--FruPl";
export var bottomRight = "IconCard-module--bottomRight--3f2K8";
export var bottomLeft = "IconCard-module--bottomLeft--2nnvh";