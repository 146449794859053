import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "./IconCard.module.scss";
import { cn } from "../../../utils";
import IconCardI from "./IconCardI.type";

const IconCard = ({
  icon,
  title,
  alignment,
  padding,
  subtext,
  radius,
  bullets,
  index,
}: IconCardI) => {
  return (
    <div
      key={`${padding}_${index}_icon`}
      className={cn([
        radius ? styles.radius : "",
        padding ? styles.padding : "",
        styles[alignment],
        styles.iconCardWrapper,
      ])}
    >
      {bullets && (
        <>
          <div className={cn([styles.circle, styles.topLeft])}></div>
          <div className={cn([styles.circle, styles.topRight])}></div>
          <div className={cn([styles.circle, styles.bottomLeft])}></div>
          <div className={cn([styles.circle, styles.bottomRight])}></div>
        </>
      )}
      <div className={styles.iconTitleImageWrapper}>
        <div className={styles.iconImageContainer}>
          <GatsbyImage
            objectFit="contain"
            image={icon.gatsbyImageData}
            alt={icon.title}
          />
        </div>
        <div className={styles.iconTitleContainer}>
          <h3>{title}</h3>
        </div>
      </div>
      <div className={styles.iconSubtextContainer}>
        <p>{subtext.subtext}</p>
      </div>
    </div>
  );
};

export default IconCard;
